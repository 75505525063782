<template>
  <nav class="navbar fixed-top navbar-expand-lg bg-body-tertiary">
  <div class="container">
    <router-link to="/" class="navbar-brand">
      <!--<img src="@/assets/logo.png" alt="Bootstrap" width="30" height="24">-->

      <i class="bi bi-boxes" style="width:30px;"></i>

    </router-link>
    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <ul class="navbar-nav me-auto mb-2 mb-lg-0">
        
        <li class="nav-item">
          <router-link to="/" class="nav-link">Главная</router-link>
        </li>
        
        
        <li class="nav-item">
          <router-link to="/search" class="nav-link navbutton">Поиск показателей</router-link>
        </li>
      
       

        <li class="nav-item">
          <router-link to="/pivot" class="nav-link navbutton">SuperPivot</router-link>
        </li>

     

        <template v-if="$store.state.isAuthenticated">
        <li class="nav-item">
          <router-link to="/dashboard/my-account" class="nav-link navbutton">Профиль</router-link>
        </li>       
        </template>

        <template v-else>

        </template>
  
      </ul>


      <div class="d-flex">
      <a href="https://gitverse.ru/alexandermcme/emiss" target="_blank" class="btn btn-outline-primary" type="submit"><i class="bi bi-github"></i> GitVerse</a>
      </div>

      
    </div>
  </div>
</nav>


<section class="section">
  <router-view/>
</section>


<div class="container">
  <footer class="footerbe">
    <div class="row">
      <div class="col-md-4">
    <p class="text-center text-body-secondary">© 2024 StatKit</p>
    </div>

    <div class="col-md-4">
    <p class="text-center">
      <i class="bi bi-boxes" style="width:40px;"></i> ЕМИСС 2.0
    </p>
    </div>

    <div class="col-md-4">
    <p class="text-center text-body-secondary">
      <i class="bi bi-envelope"></i> <span id="emailcopy"><a href="mailto:alexandermcme@gmail.com" target="_blank">alexandermcme@gmail.com</a></span></p>
    </div>
    </div>
  </footer>
</div>
</template>

<script>
import axios from 'axios'

export default {
name: 'App',
beforeCreate() {
this.$store.commit('initializeStore')
const token = this.$store.state.token

if (token) {
axios.defaults.headers.common['Authorization'] = "Token " + token
} else {
axios.defaults.headers.common['Authorization'] = ""
}
},

methods: {


  

}
}
</script>

<style>

</style>
