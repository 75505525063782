import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'





import 'bootstrap/dist/js/bootstrap.min.js'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap-icons/font/bootstrap-icons.min.css'
import '@/assets/custom.css'
import '@/assets/run.css'
import VueMeta from 'vue-meta'



axios.defaults.baseURL = '//87.242.118.177:8080/'

const app = createApp(App).use(store).use(router, axios, VueMeta).provide('$axios', axios)

app.config.globalProperties.hostdj = 'http://87.242.118.177:8000'

app.mount('#app')



