import { createRouter, createWebHistory } from 'vue-router'


const HomeView = () => import("../views/HomeView.vue");
const NotFound = () => import("../views/NotFound.vue");
const Search = () => import("../views/Search.vue");
const indicator = () => import("../views/indicator.vue");
const Pivot = () => import("../views/Pivot.vue");
const SignUp = () => import("../views/SignUp.vue");
const LogIn = () => import("../views/Login.vue");
const MyAccount = () => import("../views/dashboard/MyAccount.vue");
const add = () => import("../views/add.vue");
const deleteP = () => import("../views/delete.vue");
const Data = () => import("../views/Data.vue");

import store from '@/store';


const routes = [
  {
    path: '/',
    name: 'HomeView',

    meta: { title: 'StatKit', 
    metaTags: [
      {
        name: 'description',
        content: 'Российская статистика для data scientists'
      },
      {
        name: 'keywords',
        content: 'StatKit,data,science,R,Python'
      }
 ]

 },
    component: HomeView
  },



  {
    path: '/search',
    name: 'Search',

    meta: { title: 'Поиск показателей | StatKit', 
    metaTags: [
      {
        name: 'description',
        content: 'The home page of our example app.'
      },
      {
        name: 'keywords',
        content: 'key,some,words'
      }
 ]

 },
    component: Search
  },





  {
    path: '/data',
    name: 'Data',

    meta: { title: 'Data | StatKit', 
    metaTags: [
      {
        name: 'description',
        content: 'The home page of our example app.'
      },
      {
        name: 'keywords',
        content: 'key,some,words'
      }
 ]

 },
    component: Data
  },




  
  {
    path: '/pivot',
    name: 'Pivot',

    meta: { title: 'Сводные таблицы | StatKit', 
    metaTags: [
      {
        name: 'description',
        content: 'The home page of our example app.'
      },
      {
        name: 'keywords',
        content: 'key,some,words'
      }
 ]

 },
    component: Pivot
  },





  {
    path: '/indicator',
    name: 'indicator',

    meta: { title: 'Показатель | StatKit', 
    metaTags: [
      {
        name: 'description',
        content: 'The home page of our example app.'
      },
      {
        name: 'keywords',
        content: 'key,some,words'
      }
 ]

 },
    component: indicator
  },





  
  {
    path: '/add',
    name: 'add',

    meta: { title: 'Добавить показатель | StatKit', 
    metaTags: [
      {
        name: 'description',
        content: 'The home page of our example app.'
      },
      {
        name: 'keywords',
        content: 'key,some,words'
      }
 ]

 },
    component: add,
    meta: {
      requireLogin: true,
    }
  },


  {
    path: '/delete',
    name: 'delete',

    meta: { title: 'Удалить показатель | StatKit', 
    metaTags: [
      {
        name: 'description',
        content: 'The home page of our example app.'
      },
      {
        name: 'keywords',
        content: 'key,some,words'
      }
 ]

 },
    component: deleteP,
    meta: {
      requireLogin: true,
    }
  },



  
      {
        path: '/sign-up',
        name: 'SignUp',
        component: SignUp,
        meta: {
          requireLogin: true

        }
      },




    {
    path: '/log-in',
    name: 'LogIn',

    meta: { title: 'Вход | StatKit', 
    metaTags: [
      {
        name: 'description',
        content: 'The home page of our example app.'
      },
      {
        name: 'keywords',
        content: 'key,some,words'
      }
 ]

 },
    component: LogIn,
    meta: {
      requireLogin: false,
    }
  },





     
      {
        path:'/dashboard/my-account',
        name:'Профиль',
        meta: { title: 'Профиль | StatKit', 
    metaTags: [
      {
        name: 'description',
        content: 'The home page of our example app.'
      },
      {
        name: 'keywords',
        content: 'key,some,words'
      }
 ]
 },
        component:MyAccount,
        meta:{
          requireLogin:true
        }
      },
     

      {
        path: "/:catchAll(.*)",
        name: "NotFound",
        component: NotFound,
    }


]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {

  if(to.matched.some(record=>record.meta.requireLogin) && !store.state.isAuthenticated){
    next('/log-in')
  }

  const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title);
  const nearestWithMeta = to.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);
  const previousNearestWithMeta = from.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);
  // If a route with a title was found, set the document (page) title to that value.
  if(nearestWithTitle) {
    document.title = nearestWithTitle.meta.title;
  } else if(previousNearestWithMeta) {
    document.title = previousNearestWithMeta.meta.title;
  }
  // Remove any stale meta tags from the document using the key attribute we set below.
  Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(el => el.parentNode.removeChild(el));

  // Skip rendering meta tags if there are none.
  if(!nearestWithMeta) return next();

  // Turn the meta tag definitions into actual elements in the head.
  nearestWithMeta.meta.metaTags.map(tagDef => {
    const tag = document.createElement('meta');

    Object.keys(tagDef).forEach(key => {
      tag.setAttribute(key, tagDef[key]);
    });

     // We use this to track which meta tags we create so we don't interfere with other ones.
     tag.setAttribute('data-vue-router-controlled', '');

     return tag;
   })
   // Add the meta tags to the document head.
   .forEach(tag => document.head.appendChild(tag));
 
   next();

})

export default router
